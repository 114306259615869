import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

import './assets/main.css'

Vue.config.productionTip = false

// We implement a global shared data object for the global wallet related context
export var globalStore = new Vue({
  data: {
    priceHost: "https://cmc-prices-be.web-dario.workers.dev/",
    coinInfoHost: "https://coin-tools-be.web-dario.workers.dev/coin-infos",
    breakEvenHost: "https://coin-tools-be.web-dario.workers.dev/breakeven-plan",
  }
});

const theApp = new Vue({
  vuetify,
  router,
  render: h => h(App)
})
  
theApp.$mount('#app')
