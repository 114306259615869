<script>
import { globalStore } from '../main.js'
import axios from 'axios';

export default {
    name: 'HomeView',

    components: {
    },

    data() {
        return {
            showInEuro: false,

            currentCoinIndex: null,
            currentMiner: null,

            analysisMiner: {},
            analysisNetwork: {},
            minerPrice: 2000,
            costPerKwh: 0.1,

            analysisResults: [],
            analyzing: false,
            showError: false,
            errorMessage: null,

            scrollOptions: {
                duration: 700,
                offset: 20,
                easing: 'easeInOutCubic',
            },

            euroToDollar: { "01.22": 1.13, "02.22": 1.13, "03.22": 1.1, "04.22": 1.08, "05.22": 1.06, "06.22": 1.06, "07.22": 1.02, "08.22": 1.01, "09.22": 0.99, "10.22": 0.98, "11.22": 1.02, "12.22": 1.06, "01.23": 1.08, "02.23": 1.07, "03.23": 1.07, "04.23": 1.1, "05.23": 1.09, "06.23": 1.08, "07.23": 1.11, "08.23": 1.09, "09.23": 1.07, "10.23": 1.06, "11.23": 1.08, "12.23": 1.09, "01.24": 1.09, "02.24": 1.08 },
            lastKnownEuroToDollar: null,

            breakEvenResult: null,
            breakEvenResultLabel: null,
            headers: [
                { text: 'Date', value: 'date' },
                { text: "Daily Yield in Fiat", value: "yieldFiat" },
                { text: "Daily Yield in Coin", value: "yieldCoin" },
                { text: "Total Yield in Fiat", value: "totalYieldFiat" },
                { text: "Total Yield in Coin", value: "totalYieldCoin" },
                { text: "Hashrate", value: "hashrate" },
            ],

            items: [
            ],
        }
    },

    created() {
        this.parseCoins()
        this.currentCoinIndex = 0
        this.lastKnownEuroToDollar = Object.values(this.euroToDollar).slice(-1)
    },

    mounted() {
        this.parseAll()
    },

    computed: {
        priceApi() {
            return globalStore.priceHost
        },

        coinInfoApi() {
            return globalStore.coinInfoHost
        },

        breakEvenApi() {
            return globalStore.breakEvenHost
        },

        currentCoin() {
            return this.analysisResults[this.currentCoinIndex] ?? null
        },
    },

    watch: {
        currentCoin(newValue, oldValue) {
            if (!oldValue && newValue) {
                this.initNetwork()
            }
            this.currentMiner = this.currentCoin?.miners[0] ?? null
        },

        'currentCoin.networkInfo.currentHashrate': function (newValue, oldValue) {
            if (!oldValue && newValue) {
                this.initNetwork()
            }
        },

        currentMiner() {
            this.analysisMiner = JSON.parse(JSON.stringify(this.currentMiner))
            this.analysisNetwork = JSON.parse(JSON.stringify(this.currentCoin.networkInfo))
        },
    },

    methods: {
        initNetwork() {
            this.analysisNetwork = JSON.parse(JSON.stringify(this.currentCoin.networkInfo))
        },

        parseAll() {
            console.debug("Starting analysis")
            this.analyzing = true

            this.fetchCoinPrices()
            this.fetchCoinInfos()

            console.debug("Analysis finished")
        },

        parseCoins() {
            const coinMetadata = [
                {
                    "cmcId": 10202,
                    "coin": "KDA",
                    "coinShort": "KDA",
                    "coinName": "Kadena",
                    "miners": [
                        {
                            name: "KA3",
                            brand: "Antminer",
                            wattage: 3154,
                            minerHashrate: 166,
                            hashrateUnit: 'TH',
                            currentRewards: null,
                            lastKnownPrice: 3300,
                        },
                        {
                            name: "KD-BOX",
                            brand: "Goldshell",
                            wattage: 240,
                            minerHashrate: 1.6,
                            hashrateUnit: 'TH',
                            currentRewards: null,
                            lastKnownPrice: 181,
                        },
                    ],
                    "priceInfo": null,
                    "networkInfo": {
                        currentHashrate: null,
                        assumedHashrateGrowth: 350,
                    },
                },
                {
                    "cmcId": 10202,
                    "coin": "STC",
                    "coinShort": "STC",
                    "coinName": "StarCoin",
                    "miners": [
                        {
                            name: "ST-BOX",
                            brand: "Goldshell",
                            wattage: 61,
                            minerHashrate: 13.92,
                            hashrateUnit: 'kH',
                            currentRewards: null,
                            lastKnownPrice: 1199,
                        }
                    ],
                    "priceInfo": null,
                    "networkInfo": {
                        currentHashrate: null,
                        assumedHashrateGrowth: 0.5,
                    },
                }
            ]
            this.analysisResults.push(...coinMetadata)
        },

        async fetchCoinPrices() {
            const idsToFetch = this.analysisResults
                .filter((coin) => coin.cmcId && coin.cmcId > 0)
                .map((coin) => coin.cmcId)
                .filter((item, index, currentSet) => currentSet.indexOf(item) === index)
                .join(",")

            if (!idsToFetch) {
                return
            }

            axios
                .get(this.priceApi + '?ids=' + idsToFetch)
                .then((response) => {
                    console.debug("Fetching coin prices response:", response)

                    if (response?.data?.status?.error_code == 0) {
                        this.mergeCoinPrices(response.data.data)
                    } else {
                        console.error("Fetching coin prices error:", response.data.status)
                        this.errorMessage = response.data.status.error_message
                        this.showError = true
                    }
                })
                .catch((err) => {
                    console.error("Fetching coin prices error:", err)
                    this.errorMessage = err.status.error_message
                    this.showError = true
                })
                .finally(() => {
                    this.analyzing = false
                });
        },

        mergeCoinPrices(data) {
            if (!data) {
                console.warn("No data for merging coin prices, cancelling")
            }

            Object.entries(data).forEach(([coinName, priceData]) => {
                console.debug("Processing coin price data:", coinName, priceData)
                const allMetadata = this.analysisResults.filter(coinMetadata => coinMetadata.cmcId == coinName || coinMetadata.coinShort == coinName)

                if (allMetadata) {
                    let info
                    if (Array.isArray(priceData)) {
                        info = priceData.find(result => result.id == coinName || result.symbol == coinName)
                    } else {
                        info = priceData
                    }

                    if (info) {
                        allMetadata.forEach((metadata) => {
                            metadata.priceInfo = {
                                id: info.id,
                                name: info.name,
                                price: info.quote.USD.price,
                                percent_change_1h: info.quote.USD.percent_change_1h,
                                percent_change_24h: info.quote.USD.percent_change_24h,
                                percent_change_7d: info.quote.USD.percent_change_7d,
                                percent_change_30d: info.quote.USD.percent_change_30d,
                            }
                        })
                    }
                }
                console.debug("Metadata after adding coin prices:", allMetadata)
            })
        },

        async fetchCoinInfos() {
            const idsToFetch = this.analysisResults
                .filter((coin) => coin.coinShort && coin.coinShort.length > 0)
                .map((coin) => coin.coinShort)
                .filter((item, index, currentSet) => currentSet.indexOf(item) === index)
                .join(",")

            if (!idsToFetch) {
                return
            }

            axios
                .get(this.coinInfoApi + '?coins=' + idsToFetch)
                .then((response) => {
                    console.debug("Fetching coin infos response:", response)

                    this.mergeCoinInfos(response.data)
                })
                .catch((err) => {
                    console.error("Fetching coin infos error:", err)
                    this.errorMessage = err
                    this.showError = true
                })
                .finally(() => {
                    this.analyzing = false
                });
        },

        mergeCoinInfos(data) {
            if (!data) {
                console.warn("No data for merging coin infos, cancelling")
            }

            data.forEach((item) => {
                console.debug("Processing coin info data:", item)
                const allMetadata = this.analysisResults.filter(coinMetadata => coinMetadata.coinShort == item.coin)

                if (allMetadata) {
                    allMetadata.forEach((metadata) => {
                        metadata.networkInfo.currentHashrate = item.hashrate
                        this.analysisNetwork.currentHashrate = item.hashrate
                    })
                }
                console.debug("Metadata after adding coin infos:", allMetadata)
            })
        },

        async fetchBreakevenPlan() {
            const queryParams = {
                coin: this.currentCoin.coinShort,
                hashrate: this.analysisMiner.minerHashrate,
                unit: this.analysisMiner.hashrateUnit,
                cost: this.analysisMiner.lastKnownPrice,
                power: this.analysisMiner.wattage,
                network: this.analysisNetwork.currentHashrate,
                increase: this.analysisNetwork.assumedHashrateGrowth,
                electricity: this.costPerKwh,
            }

            axios
                .get(this.breakEvenApi + '?' + Object.entries(queryParams).map(([key, value]) => key + "=" + value).join("&"))
                .then((response) => {
                    console.debug("Fetching breakeven plan response:", response)

                    if (response?.data && response.data.coin === this.currentCoin.coinShort) {
                        const res = response.data

                        const options = {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit"
                        }

                        this.breakEvenResult = res.result
                        this.breakEvenResultLabel =
                            res.result === "BREAK_EVEN" ? `You break even on ${(new Date(Date.parse(res.outputs.slice(-1)[0].date))).toLocaleDateString(undefined, options)}`
                                : (res.result === "NO_BREAK_EVEN" ? `You cannot break even within ${(res.outputs.length / 365 * 12).toFixed(0)} months`
                                    : "Unknown result")

                        this.items = res.outputs
                    }
                })
                .catch((err) => {
                    console.error("Fetching breakeven plan error:", err)
                    this.errorMessage = err
                    this.showError = true
                })
                .finally(() => {
                    this.analyzing = false
                });
        },

        calculateElectricityBreakEven(metadata, miner) {
            if (!metadata || !metadata.priceInfo || !miner) {
                return null
            }

            const rewardsInFiat = miner.currentRewardsPerMonth * metadata.priceInfo.price
            const consumptionPerMonth = miner.consumption / 1000 * 24 * 30

            return rewardsInFiat / consumptionPerMonth
        },

        numberFormatter(num, digits) {
            const lookup = [
                { value: 1e0, symbol: "" },
                { value: 1e3, symbol: " k" },
                { value: 1e6, symbol: " M" },
                { value: 1e9, symbol: " B" },
                { value: 1e12, symbol: " T" },
                { value: 1e15, symbol: " Qa" },
            ];
            const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
            const item = lookup.findLast(item => num >= item.value);
            return item ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol) : "0";
        },

        toLocalePrice(price) {
            if (!price) {
                return null
            }
            if (this.showInEuro) {
                price /= this.lastKnownEuroToDollar
            }
            return (!this.showInEuro ? '$' : '') + price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 5, }) + (this.showInEuro ? ' €' : '')
        },
    },
}
</script>

<template>
    <v-container class="pb-md-0 px-md-0 font-size-20" width="100%" dark>
        <header class="d-flex my-8 justify-space-between" dark>
            <h1 class="no-wrap">Coin tools</h1>
            <div><v-switch v-model="showInEuro" inset color="blue" label="Show in Euro"></v-switch></div>
        </header>

        <main class="my-8">
            <v-btn-toggle v-model="currentCoinIndex">
                <v-btn v-for="(metadata, index) in analysisResults" :key="'coin-button-' + index">
                    {{ metadata.coinName }}
                </v-btn>
            </v-btn-toggle>

            <v-sheet v-if="currentCoin" class="my-5 py-5 rounded-xl" flat color="var(--color-white-1)" light>
                <h3 class="mb-5">{{ currentCoin.coinName }}</h3>

                <v-btn v-for="(miner, index) in currentCoin.miners" :key="'miner-button-' + index"
                    @click="currentMiner = miner">
                    {{ miner.name }}
                </v-btn>

                <v-alert v-if="showError" class="mb-5 rounded-lg text-wrap" type="error" dense text outlined elevation="0">
                    {{ errorMessage }}
                </v-alert>

                <v-row class="my-5">
                    <v-col cols="5">
                        <v-sheet class="mb-5 pa-5 rounded-xl" flat color="var(--color-aubergine-3)" dark>
                            <v-text-field v-model="analysisMiner.lastKnownPrice" label="Miner Price"
                                :suffix="showInEuro ? '€' : '$'" outlined dense type="number" hide-spin-buttons></v-text-field>
                            <v-text-field v-model="analysisMiner.minerHashrate" label="Miner Hashrate"
                                :suffix="analysisMiner.hashrateUnit + '/s'" outlined dense type="number" hide-spin-buttons></v-text-field>
                            <v-text-field v-model="analysisMiner.wattage" label="Power" suffix="W" outlined
                                dense type="number" hide-spin-buttons></v-text-field>
                            <v-text-field v-model="costPerKwh" label="Electricity Cost"
                                :suffix="this.showInEuro ? '€ per kWh' : '$ per kWh'" outlined dense type="number" hide-spin-buttons></v-text-field>
                            <v-text-field v-model="analysisNetwork.currentHashrate"
                                label="Network Hashrate on start of mining" :suffix="analysisMiner.hashrateUnit + '/s'"
                                outlined dense type="number" hide-spin-buttons 
                                :loading="!analysisNetwork?.currentHashrate"></v-text-field>
                            <v-text-field v-model="analysisNetwork.assumedHashrateGrowth" class="mb-0" 
                                label="Assumed hashrate increase per day" :suffix="analysisMiner.hashrateUnit + '/s'"
                                outlined dense type="number" hide-spin-buttons></v-text-field>
                        </v-sheet>
                        <v-btn class="" 
                            @click="fetchBreakevenPlan" 
                            color="blue" 
                            dark
                            :disabled="!analysisNetwork?.currentHashrate || analysisNetwork.currentHashrate <= 0"
                        >Estimate Breakeven Date</v-btn>
                    </v-col>
                    <v-col class="pl-8" cols="7">
                        <template v-if="breakEvenResult">
                            <h3 :class="breakEvenResult === 'NO_BREAK_EVEN' ? 'font-red' : ''">{{ breakEvenResultLabel }}</h3>
                            <br />
                            See details below
                        </template>
                    </v-col>
                </v-row>

                <v-data-table :headers="headers" :items="items"></v-data-table>

                <!-- <v-sheet class="my-8 pa-5 rounded-xl" flat color="var(--color-aubergine-3)" dark>
                    <div v-for="(miner, index) in currentCoin.miners" :key="currentCoin.coinName + '-miner-' + index">
                        <v-row class="mt-4 align-center">
                            <v-col cols="2"><h4>{{ miner.name }}</h4></v-col>
                            <v-col class="align-center" cols="3"><v-icon size="22" color="green">{{ showInEuro ? 'mdi-currency-eur' : 'mdi-currency-usd' }}</v-icon> Current price</v-col>
                            <v-col class="align-center" cols="3"><v-icon size="22" color="green">mdi-lightning-bolt</v-icon> Power consumption</v-col>
                            <v-col class="align-center" cols="4"><v-icon size="22" color="green">mdi-calculator</v-icon> Break even electricity cost</v-col>
                        </v-row>
                        <v-row class="mb-8 align-center">
                            <v-col cols="2"></v-col>
                            <v-col cols="3">
                                <v-progress-circular v-if="analyzing" indeterminate color="blue"></v-progress-circular>
                                <div v-else>{{ currentCoin.priceInfo?.price ? toLocalePrice(currentCoin.priceInfo.price) : 'no price info' }}</div>
                            </v-col>
                            <v-col cols="3">{{ miner.wattage }} W</v-col>
                            <v-col cols="4">
                                <v-progress-circular v-if="analyzing" indeterminate color="blue"></v-progress-circular>
                                <div v-else>{{ toLocalePrice(calculateElectricityBreakEven(currentCoin, miner)) ?? '-' }}</div>
                            </v-col>
                        </v-row>
                    </div>
                </v-sheet>
                <h4>Recent price changes</h4>
                <v-row v-if="!analyzing" class="mt-4 align-center">
                    <v-col cols="2" :class="currentCoin?.priceInfo?.percent_change_1h < 0 ? 'font-red' : 'font-green'">
                        <v-icon class="ma-0 mr-n1" :color="currentCoin?.priceInfo?.percent_change_1h < 0 ? 'red' : 'green'" size="22">{{ currentCoin.priceInfo.percent_change_1h < 0 ? 'mdi-menu-down' : 'mdi-menu-up' }}</v-icon> {{ currentCoin?.priceInfo?.percent_change_1h.toFixed(2) }}% (1h)
                    </v-col>
                    <v-col cols="2" :class="currentCoin.priceInfo.percent_change_24h < 0 ? 'font-red' : 'font-green'">
                        <v-icon class="ma-0 mr-n1" :color="currentCoin?.priceInfo?.percent_change_24h < 0 ? 'red' : 'green'" size="22">{{ currentCoin.priceInfo.percent_change_24h < 0 ? 'mdi-menu-down' : 'mdi-menu-up' }}</v-icon> {{ currentCoin?.priceInfo?.percent_change_24h.toFixed(2) }}% (24h)
                    </v-col>
                    <v-col cols="2" :class="currentCoin?.priceInfo?.percent_change_7d < 0 ? 'font-red' : 'font-green'">
                        <v-icon class="ma-0 mr-n1" :color="currentCoin?.priceInfo?.percent_change_7d < 0 ? 'red' : 'green'" size="22">{{ currentCoin.priceInfo.percent_change_7d < 0 ? 'mdi-menu-down' : 'mdi-menu-up' }}</v-icon> {{ currentCoin?.priceInfo?.percent_change_7d.toFixed(2) }}% (7d)
                    </v-col>
                    <v-col cols="2" :class="currentCoin?.priceInfo?.percent_change_30d < 0 ? 'font-red' : 'font-green'">
                        <v-icon class="ma-0 mr-n1" :color="currentCoin?.priceInfo?.percent_change_30d < 0 ? 'red' : 'green'" size="22">{{ currentCoin.priceInfo.percent_change_30d < 0 ? 'mdi-menu-down' : 'mdi-menu-up' }}</v-icon> {{ currentCoin?.priceInfo?.percent_change_30d.toFixed(2) }}% (30d)
                    </v-col>
                </v-row> -->
            </v-sheet>

        </main>
    </v-container>
</template>

<style>
.no-wrap {
    text-wrap: nowrap;
}

.font-red {
    color: red;
}

.font-green {
    color: green;
}

.font-blue {
    color: blue;
}</style>